<template>
  <div class="w-full">
    <RequestSuccessError :errors="form.hasErrors ? form.errors : null" />
    <form class="stdEl bg-green-100 sm:p-8" @submit.prevent="submit">
      <div class="max-w-screen-sm">
        <div class="w-full mb-4">
          <InputLabel for="firstname">{{ $t('firstname') }} <span class="font-normal text-sm">({{ $t('voluntarily') }})</span></InputLabel>
          <TextInput
            id="firstname"
            name="firstname"
            v-model="form.firstname"
            :placeholder="$t('firstname')"
            class="mt-1 block w-full"
            autocomplete="given-name"
          />
        </div>
        <div class="w-full mb-4">
          <InputLabel for="lastname">{{ $t('lastname') }} <span class="font-normal text-sm">({{ $t('voluntarily') }})</span></InputLabel>
          <TextInput
            id="lastname"
            name="lastname"
            v-model="form.lastname"
            :placeholder="$t('lastname')"
            class="mt-1 block w-full"
            autocomplete="family-name"
          />
        </div>
        <div class="w-full mb-4">
          <InputLabel for="email">{{ $t('email') }}</InputLabel>
          <TextInput
            id="email"
            name="email"
            v-model="form.email"
            type="email"
            :placeholder="$t('email')"
            class="mt-1 block w-full"
            autocomplete="email"
            required
          />
        </div>
        <InputLabel class="mt-4">
          <div class="flex items-center font-normal mb-4">
            <Checkbox id="gdpr" name="gdpr" v-model="form.gdpr" required />
            <div class="ml-2 text-base">
              <i18n-t keypath="agreeGdpr" tag="span">
                <template #gdprLink>
                  <Link href="/gdpr">{{ $t('privacyPolicy') }}</Link>
                </template>
              </i18n-t>
            </div>
          </div>
        </InputLabel>
      </div>
      <PrimaryButton @click="countNewsletter">{{ $t('registerForNewsletter') }}</PrimaryButton>
    </form>
  </div>
</template>

<script>
  import PrimaryButton from '@/Components/PrimaryButton.vue'
  import InputLabel from '@/Components/InputLabel.vue';
  import Checkbox from '@/Components/Checkbox.vue';
  import TextInput from '@/Components/TextInput.vue';
  import { usePage, useForm, Link, router } from '@inertiajs/vue3'
  import RequestSuccessError from '@/Shared/RequestSuccessError.vue'

  export default
  {
    data() {
      return {
        form: useForm({
          firstname: null,
          lastname: null,
          email: null,
          gdpr: false,
        })
      }
    },
    components:
    {
      PrimaryButton,
      InputLabel,
      Checkbox,
      TextInput,
      Link,
      RequestSuccessError
    },
    methods:
    {
      submit()
      {
        router.post('/newsletter', this.form)
      },

      countNewsletter()
      {
        if (typeof gtag !== 'undefined')
        {
          gtag('event', 'conversion', {
              'send_to': 'AW-633552859/RFHoCIiDhIgCENv_jK4C',
              'transaction_id': ''
          });
          gtag( 'event', 'newsletter');
        }
      }
    },
    computed:
    {
       errors() {
        return usePage().errors
      }
    }
  }
</script>
